import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import EndrexLogo from '@shared/ui/display/EndrexLogo';
import { alignToCenter, ContainerColumn } from '@components/styled';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import ResetButton from '@shared/ui/buttons/ResetButton';
import { LinkContainer as LinkContainerComponent, Title as TitleComponent, } from '../shared';
const Container = alignToCenter(ContainerColumn);
const FormContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    height: 'inherit',
    flex: 1,
    paddingTop: 62,
}));
const NavContainer = styled(Container)(props => ({
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 100px',
    justifyContent: props.showNavigation ? 'space-between' : 'center',
    width: 'calc(100% - 200px)',
    height: 40,
}));
const InnerContainer = styled(Container, { shouldForwardProp: propName => propName !== 'flexValue' })(props => ({
    flex: props.flexValue,
    justifyContent: 'start',
    marginTop: 110,
}));
const LinkContainer = styled(LinkContainerComponent, { shouldForwardProp: propName => propName !== 'flexValue' })(props => ({
    display: 'inline-flex',
    flex: props.flexValue,
    paddingTop: 30,
}));
const Title = styled(TitleComponent, { shouldForwardProp: propName => propName !== 'titleSpacing' })(props => ({
    textAlign: 'center',
    marginTop: 0,
    margin: props.titleSpacing ? props.titleSpacing : `0 0 ${props.theme.spacing_sizes.xs * 5}px 0`,
}));
const ButtonContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'end',
    // added to make buttons equal in width so the logo would be displayed at center
    minWidth: 84,
}));
const FormWrapper = ({ titleText = undefined, renderInnerForm = undefined, renderLinks = undefined, showNavigation = false, backLinkProps = undefined, handleReset = undefined, titleSpacing = undefined, flexRatio = [4, 4], }) => {
    const theme = useTheme();
    return (_jsxs(FormContainer, { children: [_jsxs(NavContainer, { showNavigation: showNavigation, children: [showNavigation ? (_jsx(ButtonContainer, { children: _jsx(BackLinkButton, { onClick: backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.onClick, children: (backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.text) || '' }) })) : null, _jsx(EndrexLogo, {}), showNavigation ? (_jsx(ButtonContainer, { children: _jsx(ResetButton, { iconPadding: theme.spacing_sizes.s, iconHeight: 24, iconWidth: 24, onClick: handleReset }) })) :
                        null] }), _jsxs(InnerContainer, { flexValue: flexRatio[0], children: [_jsx(Title, { titleSpacing: titleSpacing, children: titleText }), renderInnerForm || null] }), _jsx(LinkContainer, { flexValue: flexRatio[1], children: renderLinks || null })] }));
};
export default FormWrapper;
